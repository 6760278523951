import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, Teleport as _Teleport, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-367cb4ba"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "title-row"
}
const _hoisted_2 = { class: "template-title" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "right-area" }
const _hoisted_5 = {
  key: 0,
  class: "file-tab-mobile"
}
const _hoisted_6 = { class: "dropdown-row" }
const _hoisted_7 = { class: "pl-2 pr-2" }
const _hoisted_8 = { class: "container-draggable-resizable" }
const _hoisted_9 = { class: "dropdown-row" }
const _hoisted_10 = { class: "right-header" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = ["value"]
const _hoisted_13 = { class: "row" }
const _hoisted_14 = { class: "file-header pl-3" }
const _hoisted_15 = { class: "left-header" }
const _hoisted_16 = ["draggable", "onDragstart"]
const _hoisted_17 = ["src"]
const _hoisted_18 = ["draggable", "onDragstart"]
const _hoisted_19 = ["src"]
const _hoisted_20 = ["draggable", "onDragstart"]
const _hoisted_21 = ["src"]
const _hoisted_22 = { class: "right-header" }
const _hoisted_23 = ["value", "onInput"]
const _hoisted_24 = { class: "file-content" }
const _hoisted_25 = { class: "container-draggable-resizable" }
const _hoisted_26 = {
  key: 3,
  class: "button-row mt-3"
}
const _hoisted_27 = {
  key: 4,
  class: "button-row mt-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderTitleBar = _resolveComponent("HeaderTitleBar")!
  const _component_BreadScrum = _resolveComponent("BreadScrum")!
  const _component_TextButton = _resolveComponent("TextButton")!
  const _component_FlatButton = _resolveComponent("FlatButton")!
  const _component_StepProgressBar = _resolveComponent("StepProgressBar")!
  const _component_dropdown = _resolveComponent("dropdown")!
  const _component_MenuOptionPopup = _resolveComponent("MenuOptionPopup")!
  const _component_TextInputDraggableResizable = _resolveComponent("TextInputDraggableResizable")!
  const _component_DraggableContainer = _resolveComponent("DraggableContainer")!
  const _component_SignTextField = _resolveComponent("SignTextField")!
  const _component_SignStampField = _resolveComponent("SignStampField")!
  const _component_SignCheckboxField = _resolveComponent("SignCheckboxField")!
  const _component_PdfPreviewVars = _resolveComponent("PdfPreviewVars")!
  const _component_FileTab = _resolveComponent("FileTab")!
  const _component_FileTabs = _resolveComponent("FileTabs")!
  const _component_EditBottomNavBar = _resolveComponent("EditBottomNavBar")!
  const _component_AfterLoginPageLayout = _resolveComponent("AfterLoginPageLayout")!
  const _component_EditCancelPopup = _resolveComponent("EditCancelPopup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AfterLoginPageLayout, {
      class: "contract-var",
      "is-sub-header-menu": true
    }, {
      title: _withCtx(() => [
        (!_ctx.isEdit)
          ? (_openBlock(), _createBlock(_component_HeaderTitleBar, {
              key: 0,
              title: _ctx.$t('contractVars.bannerText'),
              "save-button-text": _ctx.$t('contractVars.bannerSave'),
              "delete-button-text": _ctx.canDelete ? _ctx.$t('contractVars.bannerDelete') : '',
              onSave: _ctx.onSave,
              "on-delete": _ctx.deleteDraftContract
            }, null, 8, ["title", "save-button-text", "delete-button-text", "onSave", "on-delete"]))
          : (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_BreadScrum, {
                paths: _ctx.breadScrumPaths,
                class: "breadscrum-grey"
              }, null, 8, ["paths"]),
              _createVNode(_component_TextButton, {
                text: _ctx.$t('sign.returnToContract'),
                icon: require('icons/prev-arrow.svg'),
                "is-show-text": !_ctx.isMobile,
                onOnClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setPopup('ContractEditCancel')))
              }, null, 8, ["text", "icon", "is-show-text"])
            ]))
      ]),
      topLeft: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t("contractVars.title")), 1),
          (_ctx.isMobile)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, "(3/" + _toDisplayString(_ctx.progressItems.length) + ")", 1))
            : _createCommentVNode("", true)
        ])
      ]),
      bottomRight: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          (_ctx.isEdit && !_ctx.isMobile)
            ? (_openBlock(), _createBlock(_component_FlatButton, {
                key: 0,
                text: _ctx.$t('common.saveChanges'),
                class: "w-full mb-5",
                onClick: _ctx.onEdit
              }, null, 8, ["text", "onClick"]))
            : _createCommentVNode("", true),
          _createVNode(_component_StepProgressBar, {
            items: _ctx.progressItems,
            "is-edit": _ctx.isEdit,
            "current-index": "2"
          }, null, 8, ["items", "is-edit"])
        ])
      ]),
      bottomLeft: _withCtx(({ detectScrollbar }) => [
        (_ctx.isMobile)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_dropdown, {
                  class: "file-dropdown",
                  options: _ctx.files,
                  "default-option": _ctx.selectedFile.id,
                  "onUpdate:value": _ctx.onSelectedFile
                }, null, 8, ["options", "default-option", "onUpdate:value"]),
                _createVNode(_component_MenuOptionPopup, { class: "more-option" }, {
                  target: _withCtx(({ showMenuOption }) => [
                    _createVNode(_component_TextButton, {
                      icon: require('icons/more.svg'),
                      "is-show-text": false,
                      onOnClick: showMenuOption
                    }, null, 8, ["icon", "onOnClick"])
                  ]),
                  options: _withCtx(() => [
                    _createElementVNode("div", _hoisted_7, [
                      _createVNode(_component_TextButton, {
                        onOnClick: _cache[1] || (_cache[1] = ($event: any) => (
                    _ctx.addStamp(_ctx.selectedFile, _ctx.selectedFile.currentPage, {
                      initX: 0,
                      initY: 0,
                      isMobile: true,
                      isRound: _ctx.companyInfo.imprintType === 1,
                      stampSize: _ctx.companyInfo.imprintSize || 21,
                    })
                  )),
                        text: _ctx.$t('contractVars.stamp'),
                        icon: 
                    _ctx.companyInfo.imprintType === 1
                      ? require('icons/add-stamp.svg')
                      : require('icons/add-stamp-square-orange.svg')
                  ,
                        "disabled-icon": 
                    _ctx.companyInfo.imprintType === 1
                      ? require('icons/add-stamp-grey.svg')
                      : require('icons/add-stamp-square-grey.svg')
                  ,
                        enabled: !_ctx.onlyInformation,
                        class: "button-in-row"
                      }, null, 8, ["text", "icon", "disabled-icon", "enabled"]),
                      _createVNode(_component_TextButton, {
                        onOnClick: _cache[2] || (_cache[2] = ($event: any) => (
                    _ctx.addText(_ctx.selectedFile, false, _ctx.selectedFile.currentPage, {
                      initX: 0,
                      initY: 0,
                      isMobile: true,
                    })
                  )),
                        text: _ctx.$t('contractVars.textField'),
                        icon: require('icons/add-text.svg'),
                        "disabled-icon": require('icons/add-text-grey.svg'),
                        enabled: !_ctx.onlyInformation,
                        class: "button-in-row"
                      }, null, 8, ["text", "icon", "disabled-icon", "enabled"]),
                      _createVNode(_component_TextButton, {
                        onOnClick: _cache[3] || (_cache[3] = ($event: any) => (
                    _ctx.addText(_ctx.selectedFile, true, _ctx.selectedFile.currentPage, {
                      initX: 0,
                      initY: 0,
                      isMobile: true,
                    })
                  )),
                        text: _ctx.$t('contractVars.checkBox'),
                        icon: require('icons/add-checkbox.svg'),
                        "disabled-icon": require('icons/add-checkbox-grey.svg'),
                        enabled: !_ctx.onlyInformation,
                        class: "button-in-row"
                      }, null, 8, ["text", "icon", "disabled-icon", "enabled"])
                    ])
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_PdfPreviewVars, {
                  "file-path": _ctx.selectedFile.path,
                  "file-name": _ctx.selectedFile.name,
                  "file-type": _ctx.selectedFile.type,
                  page: _ctx.selectedFile.currentPage,
                  onOnReady: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onReady(_ctx.selectedFile, $event))),
                  onPageScaled: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onPageScaled(_ctx.selectedFile, $event))),
                  onPageSized: _cache[6] || (_cache[6] = ($event: any) => (_ctx.onPageSized(_ctx.selectedFile, $event))),
                  onPageLoaded: _cache[7] || (_cache[7] = 
              (page) => {
                _ctx.isLoadingPdfPage = false;
                _ctx.onPageLoaded(_ctx.selectedFile, page);
              }
            ),
                  class: "workspace"
                }, {
                  default: _withCtx(() => [
                    (!_ctx.onlyInformation && !_ctx.isLoadingPdfPage)
                      ? (_openBlock(), _createBlock(_component_DraggableContainer, { key: 0 }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedFile.inputs.filter(
                  (input) => input.page === _ctx.selectedFile.currentPage
                ), (field, index) => {
                              return (_openBlock(), _createBlock(_component_TextInputDraggableResizable, {
                                key: field.id,
                                id: field.id,
                                index: index,
                                "is-optional-text": field.isOptionalText,
                                "is-stamp": field.isStamp,
                                "init-h": field.h,
                                "init-w": field.w,
                                "init-x": field.x,
                                "init-y": field.y,
                                "init-padding": field.padding,
                                "min-height": field.minHeight,
                                "init-font-size": field.fontSize,
                                "default-signer": 
                  _ctx.signers.find(
                    (signer) => signer.order === field.contractSignerOrder
                  )?.id || ''
                ,
                                signers: _ctx.signers,
                                "is-checked": field.isChecked,
                                type: field.type,
                                text: field.text,
                                "stamp-type": field.isRoundStamp,
                                "is-edit": _ctx.isEdit,
                                suggestions: _ctx.suggestTexts,
                                onIsCheckedByDefault: 
                  (payload) =>
                    _ctx.updateCheckedByDefault(_ctx.selectedFile, field.id, payload)
                ,
                                onIsRequired: 
                  (payload) => _ctx.updateIsRequired(_ctx.selectedFile, field.id, payload)
                ,
                                onUpdateCheckBoxText: 
                  (payload) =>
                    _ctx.updateCheckBoxText(_ctx.selectedFile, field.id, payload)
                ,
                                onUpdateFontSize: 
                  (payload) => _ctx.updateFontSize(_ctx.selectedFile, field.id, payload)
                ,
                                onOnRemoveItem: ($event: any) => (_ctx.onRemoveInput(_ctx.selectedFile, field.id)),
                                onOnUpdate: 
                  (payload) => _ctx.updateField(_ctx.selectedFile, field.id, payload)
                ,
                                onOnUpdateSigner: 
                  (payload) => _ctx.updateSigner(_ctx.selectedFile, field.id, payload)
                
                              }, null, 8, ["id", "index", "is-optional-text", "is-stamp", "init-h", "init-w", "init-x", "init-y", "init-padding", "min-height", "init-font-size", "default-signer", "signers", "is-checked", "type", "text", "stamp-type", "is-edit", "suggestions", "onIsCheckedByDefault", "onIsRequired", "onUpdateCheckBoxText", "onUpdateFontSize", "onOnRemoveItem", "onOnUpdate", "onOnUpdateSigner"]))
                            }), 128))
                          ]),
                          _: 1
                        }))
                      : (_ctx.contractInformation.status < 3)
                        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.selectedFile.inputs.filter(
                (input) => input.page === _ctx.selectedFile.currentPage
              ), (field, index) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: field.id || index
                            }, [
                              (
                  field.contractSignerOrder > _ctx.contractInformation.countSigned
                )
                                ? (_openBlock(), _createBlock(_component_SignTextField, {
                                    key: 0,
                                    field: {
                  ...field,
                  width: field.w,
                  height: field.h * _ctx.selectedFile.scale,
                  positionX: field.x,
                  positionY: field.y,
                },
                                    readonly: true
                                  }, null, 8, ["field"]))
                                : _createCommentVNode("", true),
                              (
                  field.contractSignerOrder > _ctx.contractInformation.countSigned
                )
                                ? (_openBlock(), _createBlock(_component_SignStampField, {
                                    key: 1,
                                    field: {
                  ...field,
                  width: field.w / 2,
                  height: field.w / 2,
                  positionX: field.x + field.w / 4 + 10,
                  positionY: field.y + 10,
                },
                                    readonly: true,
                                    "is-preview": true
                                  }, null, 8, ["field"]))
                                : _createCommentVNode("", true),
                              (
                  field.contractSignerOrder > _ctx.contractInformation.countSigned
                )
                                ? (_openBlock(), _createBlock(_component_SignCheckboxField, {
                                    key: 2,
                                    field: {
                  ...field,
                  isOptionalCheckbox: field.isOptionalText,
                  width: field.w,
                  height: field.h * _ctx.selectedFile.scale,
                  positionX: field.x,
                  positionY: field.y,
                },
                                    readonly: true
                                  }, null, 8, ["field"]))
                                : _createCommentVNode("", true)
                            ]))
                          }), 128))
                        : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["file-path", "file-name", "file-type", "page"])
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("input", {
                      type: "text",
                      class: "jump-page",
                      value: _ctx.selectedFile.currentPage,
                      onInput: _cache[8] || (_cache[8] = ($event: any) => (_ctx.setPage(_ctx.selectedFile, $event)))
                    }, null, 40, _hoisted_12),
                    _createElementVNode("p", null, "/" + _toDisplayString(_ctx.selectedFile.pageNums), 1)
                  ]),
                  _createElementVNode("div", _hoisted_13, [
                    _createVNode(_component_TextButton, {
                      class: "prev-page",
                      icon: require('icons/bold-prev-arrow.svg'),
                      "disabled-icon": require('icons/prev-arrow-grey.svg'),
                      enabled: _ctx.selectedFile.currentPage >= 2,
                      onOnClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.prevPage(_ctx.selectedFile)))
                    }, null, 8, ["icon", "disabled-icon", "enabled"]),
                    _createVNode(_component_TextButton, {
                      class: "next-page",
                      "right-icon": require('icons/next-arrow.svg'),
                      "disabled-icon": require('icons/next-arrow-grey.svg'),
                      enabled: _ctx.selectedFile.currentPage != _ctx.selectedFile.pageNums,
                      onOnClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.nextPage(_ctx.selectedFile)))
                    }, null, 8, ["right-icon", "disabled-icon", "enabled"])
                  ])
                ])
              ])
            ]))
          : _createCommentVNode("", true),
        (!_ctx.isMobile && _ctx.isMounted)
          ? (_openBlock(), _createBlock(_component_FileTabs, {
              key: 1,
              "tab-name": _ctx.files,
              "teleport-tabs-header-to": ".top-part",
              onOnChangeTab: _cache[12] || (_cache[12] = ($event: any) => (_ctx.isLoadingPdfPage = true))
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.files, (file) => {
                  return (_openBlock(), _createBlock(_component_FileTab, {
                    key: file.id,
                    name: file.name
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(), _createBlock(_Teleport, { to: ".top-part" }, [
                        _createElementVNode("div", _hoisted_14, [
                          _createElementVNode("div", _hoisted_15, [
                            _createElementVNode("div", {
                              draggable: !_ctx.onlyInformation ? true : false,
                              onDragstart: 
                    ($event) => _ctx.dragStamp($event, file, _ctx.companyInfo.imprintType)
                  ,
                              class: "draggable-button button-in-row"
                            }, [
                              _createElementVNode("img", {
                                draggable: "false",
                                src: require('icons/drag-grey.svg')
                              }, null, 8, _hoisted_17),
                              _createVNode(_component_TextButton, {
                                draggable: "false",
                                onOnClick: ($event: any) => (
                      _ctx.addStamp(file, file.currentPage, {
                        initX: 0,
                        initY: 0,
                        isMobile: false,
                        isRound: _ctx.companyInfo.imprintType === 1,
                        stampSize: _ctx.companyInfo.imprintSize || 21,
                      })
                    ),
                                text: _ctx.$t('contractVars.stamp'),
                                icon: 
                      _ctx.companyInfo.imprintType === 1
                        ? require('icons/add-stamp.svg')
                        : require('icons/add-stamp-square-orange.svg')
                    ,
                                "disabled-icon": 
                      _ctx.companyInfo.imprintType === 1
                        ? require('icons/add-stamp-grey.svg')
                        : require('icons/add-stamp-square-grey.svg')
                    ,
                                enabled: !_ctx.onlyInformation
                              }, null, 8, ["onOnClick", "text", "icon", "disabled-icon", "enabled"])
                            ], 40, _hoisted_16),
                            _createElementVNode("div", {
                              draggable: !_ctx.onlyInformation ? true : false,
                              onDragstart: ($event) => _ctx.dragText($event, file),
                              class: "draggable-button button-in-row"
                            }, [
                              _createElementVNode("img", {
                                draggable: "false",
                                src: require('icons/drag-grey.svg')
                              }, null, 8, _hoisted_19),
                              _createVNode(_component_TextButton, {
                                draggable: "false",
                                onOnClick: ($event: any) => (_ctx.addText(file, false, file.currentPage)),
                                text: _ctx.$t('contractVars.textField'),
                                icon: require('icons/add-text.svg'),
                                "disabled-icon": require('icons/add-text-grey.svg'),
                                enabled: !_ctx.onlyInformation
                              }, null, 8, ["onOnClick", "text", "icon", "disabled-icon", "enabled"])
                            ], 40, _hoisted_18),
                            _createElementVNode("div", {
                              draggable: !_ctx.onlyInformation ? true : false,
                              onDragstart: ($event) => _ctx.dragCheckbox($event, file),
                              class: "draggable-button button-in-row"
                            }, [
                              _createElementVNode("img", {
                                draggable: "false",
                                src: require('icons/drag-grey.svg')
                              }, null, 8, _hoisted_21),
                              _createVNode(_component_TextButton, {
                                draggable: "false",
                                onOnClick: ($event: any) => (_ctx.addText(file, true, file.currentPage)),
                                text: _ctx.$t('contractVars.checkBox'),
                                icon: require('icons/add-checkbox.svg'),
                                "disabled-icon": require('icons/add-checkbox-grey.svg'),
                                enabled: !_ctx.onlyInformation
                              }, null, 8, ["onOnClick", "text", "icon", "disabled-icon", "enabled"])
                            ], 40, _hoisted_20)
                          ]),
                          _createElementVNode("div", _hoisted_22, [
                            _createElementVNode("input", {
                              type: "text",
                              class: "jump-page",
                              value: file.currentPage,
                              onInput: ($event: any) => (_ctx.setPage(file, $event))
                            }, null, 40, _hoisted_23),
                            _createElementVNode("p", null, "/" + _toDisplayString(file.pageNums), 1),
                            _createVNode(_component_TextButton, {
                              class: "prev-page",
                              icon: require('icons/bold-prev-arrow.svg'),
                              "disabled-icon": require('icons/prev-arrow-grey.svg'),
                              enabled: file.currentPage >= 2,
                              onOnClick: ($event: any) => (_ctx.prevPage(file))
                            }, null, 8, ["icon", "disabled-icon", "enabled", "onOnClick"]),
                            _createVNode(_component_TextButton, {
                              class: "next-page",
                              "right-icon": require('icons/next-arrow.svg'),
                              "disabled-icon": require('icons/next-arrow-grey.svg'),
                              enabled: file.currentPage != file.pageNums,
                              onOnClick: ($event: any) => (_ctx.nextPage(file))
                            }, null, 8, ["right-icon", "disabled-icon", "enabled", "onOnClick"])
                          ])
                        ])
                      ])),
                      _createElementVNode("div", _hoisted_24, [
                        _createElementVNode("div", _hoisted_25, [
                          _createVNode(_component_PdfPreviewVars, {
                            "file-path": file.path,
                            "file-name": file.name,
                            "file-type": file.type,
                            page: file.currentPage,
                            onOnReady: ($event: any) => (_ctx.onReady(file, $event)),
                            onPageScaled: ($event: any) => (_ctx.onPageScaled(file, $event)),
                            onPageSized: ($event: any) => (_ctx.onPageSized(file, $event)),
                            onPageLoaded: 
                  (page) => {
                    _ctx.isLoadingPdfPage = false;
                    detectScrollbar();
                    _ctx.onPageLoaded(file, page);
                  }
                ,
                            class: "workspace",
                            onDragover: _cache[11] || (_cache[11] = _withModifiers(() => {}, ["prevent"])),
                            onDrop: _withModifiers(_ctx.handleDrag, ["prevent"])
                          }, {
                            default: _withCtx(() => [
                              (!_ctx.onlyInformation && !_ctx.isLoadingPdfPage)
                                ? (_openBlock(), _createBlock(_component_DraggableContainer, { key: 0 }, {
                                    default: _withCtx(() => [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(file.inputs.filter(
                      (input) => input.page === file.currentPage
                    ), (field, index) => {
                                        return (_openBlock(), _createBlock(_component_TextInputDraggableResizable, {
                                          key: field.id,
                                          id: field.id,
                                          index: index,
                                          "is-optional-text": field.isOptionalText,
                                          "is-stamp": field.isStamp,
                                          "init-h": field.h,
                                          "init-w": field.w,
                                          "init-x": field.x,
                                          "init-y": field.y,
                                          "init-padding": field.padding,
                                          "min-height": field.minHeight,
                                          "init-font-size": field.fontSize,
                                          "default-signer": 
                      _ctx.signers.find(
                        (signer) => signer.order === field.contractSignerOrder
                      )?.id || ''
                    ,
                                          signers: _ctx.signers,
                                          "is-checked": field.isChecked,
                                          type: field.type,
                                          text: field.text,
                                          "stamp-type": field.isRoundStamp,
                                          "is-edit": _ctx.isEdit,
                                          suggestions: _ctx.suggestTexts,
                                          onIsCheckedByDefault: 
                      (payload) =>
                        _ctx.updateCheckedByDefault(file, field.id, payload)
                    ,
                                          onIsRequired: 
                      (payload) => _ctx.updateIsRequired(file, field.id, payload)
                    ,
                                          onUpdateCheckBoxText: 
                      (payload) => _ctx.updateCheckBoxText(file, field.id, payload)
                    ,
                                          onUpdateFontSize: 
                      (payload) => _ctx.updateFontSize(file, field.id, payload)
                    ,
                                          onOnRemoveItem: ($event: any) => (_ctx.onRemoveInput(file, field.id)),
                                          onOnUpdate: 
                      (payload) => _ctx.updateField(file, field.id, payload)
                    ,
                                          onOnUpdateSigner: 
                      (payload) => _ctx.updateSigner(file, field.id, payload)
                    
                                        }, null, 8, ["id", "index", "is-optional-text", "is-stamp", "init-h", "init-w", "init-x", "init-y", "init-padding", "min-height", "init-font-size", "default-signer", "signers", "is-checked", "type", "text", "stamp-type", "is-edit", "suggestions", "onIsCheckedByDefault", "onIsRequired", "onUpdateCheckBoxText", "onUpdateFontSize", "onOnRemoveItem", "onOnUpdate", "onOnUpdateSigner"]))
                                      }), 128))
                                    ]),
                                    _: 2
                                  }, 1024))
                                : (_ctx.contractInformation.status < 3)
                                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(file.inputs.filter(
                    (input) => input.page === file.currentPage
                  ), (field, index) => {
                                      return (_openBlock(), _createElementBlock("div", {
                                        key: field.id || index
                                      }, [
                                        (
                      field.contractSignerOrder >
                      _ctx.contractInformation.countSigned
                    )
                                          ? (_openBlock(), _createBlock(_component_SignTextField, {
                                              key: 0,
                                              field: {
                      ...field,
                      width: field.w,
                      height: field.h,
                      positionX: field.x,
                      positionY: field.y,
                    },
                                              readonly: true
                                            }, null, 8, ["field"]))
                                          : _createCommentVNode("", true),
                                        (
                      field.contractSignerOrder >
                      _ctx.contractInformation.countSigned
                    )
                                          ? (_openBlock(), _createBlock(_component_SignStampField, {
                                              key: 1,
                                              field: {
                      ...field,
                      width: field.w / 2,
                      height: field.w / 2,
                      positionX: field.x + field.w / 4 + 10,
                      positionY: field.y + 10,
                    },
                                              readonly: true,
                                              "is-preview": true
                                            }, null, 8, ["field"]))
                                          : _createCommentVNode("", true),
                                        (
                      field.contractSignerOrder >
                      _ctx.contractInformation.countSigned
                    )
                                          ? (_openBlock(), _createBlock(_component_SignCheckboxField, {
                                              key: 2,
                                              field: {
                      ...field,
                      isOptionalCheckbox: field.isOptionalText,
                      width: field.w,
                      height: field.h,
                      positionX: field.x,
                      positionY: field.y,
                    },
                                              readonly: true
                                            }, null, 8, ["field"]))
                                          : _createCommentVNode("", true)
                                      ]))
                                    }), 128))
                                  : _createCommentVNode("", true)
                            ]),
                            _: 2
                          }, 1032, ["file-path", "file-name", "file-type", "page", "onOnReady", "onPageScaled", "onPageSized", "onPageLoaded", "onDrop"])
                        ])
                      ])
                    ]),
                    _: 2
                  }, 1032, ["name"]))
                }), 128))
              ]),
              _: 2
            }, 1032, ["tab-name"]))
          : _createCommentVNode("", true),
        (_ctx.isMobile && _ctx.isEdit)
          ? (_openBlock(), _createBlock(_component_EditBottomNavBar, {
              key: 2,
              "on-next": _ctx.enableNextButton ? _ctx.next : null,
              "on-edit": _ctx.onEdit,
              "on-back": _ctx.back
            }, null, 8, ["on-next", "on-edit", "on-back"]))
          : (!_ctx.isMobile)
            ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                _createVNode(_component_FlatButton, {
                  class: "transition-button",
                  text: _ctx.$t('templateSigner.backButton'),
                  icon: require('icons/prev-arrow-white.svg'),
                  onOnClick: _ctx.back
                }, null, 8, ["text", "icon", "onOnClick"]),
                _createVNode(_component_FlatButton, {
                  class: "transition-button",
                  text: _ctx.$t('common.next'),
                  "right-icon": 
            _ctx.enableNextButton
              ? require('icons/next-arrow-white.svg')
              : require('icons/next-arrow-grey.svg')
          ,
                  enabled: _ctx.enableNextButton,
                  onOnClick: _ctx.next
                }, null, 8, ["text", "right-icon", "enabled", "onOnClick"])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_27, [
                _createVNode(_component_TextButton, {
                  icon: require('icons/prev-arrow.svg'),
                  text: _ctx.$t('templateSigner.backButton'),
                  onOnClick: _ctx.back
                }, null, 8, ["icon", "text", "onOnClick"]),
                _createVNode(_component_TextButton, {
                  "right-icon": require('icons/next-arrow.svg'),
                  text: _ctx.$t('common.next'),
                  onOnClick: _ctx.next,
                  enabled: _ctx.enableNextButton,
                  "disabled-icon": require('icons/next-arrow-grey.svg')
                }, null, 8, ["right-icon", "text", "onOnClick", "enabled", "disabled-icon"])
              ]))
      ]),
      _: 1
    }),
    _createVNode(_component_EditCancelPopup, {
      onOnCancel: _cache[13] || (_cache[13] = ($event: any) => (_ctx.backToFolder(_ctx.folderId, _ctx.folderName, _ctx.contractStatus)))
    })
  ], 64))
}