
import {
  computed,
  defineComponent,
  inject,
  onMounted,
  reactive,
  toRefs,
} from "vue";
import AfterLoginPageLayout from "@/views/layouts/AfterLoginPageLayout.vue";
import Dropdown from "@/components/atomics/Dropdown.vue";
import TextButton from "@/components/atomics/TextButton.vue";
import StepProgressBar from "@/components/atomics/StepProgressBar.vue";
import HeaderTitleBar from "@/components/parts/HeaderTitleBar.vue";
import BreadScrum from "@/components/atomics/BreadScrum.vue";
import FlatButton from "@/components/atomics/FlatButton.vue";
import FileTabs from "@/components/atomics/file-tabs/FileTabs.vue";
import FileTab from "@/components/atomics/file-tabs/FileTab.vue";
import MenuOptionPopup from "@/components/atomics/MenuOptionPopup.vue";
import { DraggableContainer } from "vue3-draggable-resizable";
import TextInputDraggableResizable from "@/components/atomics/vars/TextInputDraggableResizable.vue";
import EditCancelPopup from "@/components/popups/contract/EditCancelPopup.vue";
import EditBottomNavBar from "@/components/organisms/EditBottomNavBar.vue";
import PdfPreviewVars from "@/components/parts/PdfPreviewVars.vue";
import SignTextField from "@/components/atomics/sign-fields/SignTextField.vue";
import SignStampField from "@/components/atomics/sign-fields/SignStampField.vue";
import SignCheckboxField from "@/components/atomics/sign-fields/SignCheckboxField.vue";
import { useI18n } from "vue-i18n";
import { mapMutations, useStore } from "vuex";
import useInputVars from "@/lib/compositional-logic/useInputVars";
import { useRoute, useRouter } from "vue-router";
import {
  CompanyRepository,
  RepositoryFactory,
  TemplateRepository,
} from "@/lib/https";
import { buildContractEditBreadScrumPath } from "@/lib/utility/stringUtil";
import useContract from "./contract";
import { FileInfo } from "@/models/contracts/FileInfo";
import moment from "moment";
import { formatLocalDate } from "@/lib/utility/common";

export default defineComponent({
  name: "ContractVars",
  components: {
    AfterLoginPageLayout,
    TextButton,
    StepProgressBar,
    HeaderTitleBar,
    FileTab,
    FileTabs,
    DraggableContainer,
    TextInputDraggableResizable,
    Dropdown,
    PdfPreviewVars,
    BreadScrum,
    FlatButton,
    MenuOptionPopup,
    EditBottomNavBar,
    EditCancelPopup,
    SignTextField,
    SignStampField,
    SignCheckboxField,
  },
  setup() {
    const { t } = useI18n();
    const state = reactive({
      progressItems: [
        {
          name: t("template.progressAddFile"),
          event: () => router.push({ name: "Contract", params: route.params }),
        },
        {
          name: t("template.progressSigner"),
          event: () =>
            router.push({ name: "ContractSigners", params: route.params }),
        },
        { name: t("template.progressVars") },
        { name: t("contract.progressConfirm") },
        { name: t("template.progressDone") },
      ],
      files: [] as Array<any>,
      signers: [] as Array<any>,
      selectedFile: null,
      breadScrumPaths: [],
      isEdit: false,
      canDelete: true,
      onlyInformation: false,
      isMounted: false,

      folderId: "",
      folderName: "",
      contractStatus: "",
      isLoadingPdfPage: true,

      companyInfo: {} as any,
    });

    const isMobile = inject("isMobile");
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const { onPageSized, ...inputVars } = useInputVars();

    const contractInformation = store.getters["template/getTemplateInfo"];

    const {
      editProgressBarState,
      checkingAndNavigation,
      resetState,
      backToFolder,
      saveEditContract,
      saveDraftContract,
      deleteDraftContract,
      showSuccessNotification,
      showErrorNotification,
    } = useContract();
    const { getCompany } =
      RepositoryFactory.getRepository<CompanyRepository>(CompanyRepository);
    state.files = contractInformation.files;

    state.signers = contractInformation.contractSigners.map((signer: any) => {
      return {
        ...signer,
        id: signer.userId || signer.placeholder || signer.email,
        name: signer.lastName + " " + signer.firstName,
      };
    });

    state.selectedFile = state.files[0];

    // Modified draft contract
    const onSave = async () => {
      await saveDraftContract();
    };

    // Edit Contract
    const onEdit = async () => {
      await saveEditContract();
    };
    //Next step

    const back = () => {
      router.push({
        name: "ContractSigners",
        params: route.params,
        query: route.query,
      });
    };

    const enableNextButton = computed(() => {
      let isEnabled = true;
      contractInformation.files.forEach((file: any) => {
        file.inputs.forEach((field: any) => {
          if (field.contractSignerOrder === 0) {
            isEnabled = false;
          }
        });
      });
      return isEnabled;
    });

    const contractDetailInfo = contractInformation.contractInformation;
    const suggestTexts = computed(() =>
      [
        contractDetailInfo.contractOverview,
        formatLocalDate(
          contractDetailInfo.contractConclusionDate,
          t("contractSummary.dateFormat")
        ),
        formatLocalDate(
          contractDetailInfo.contractStartDate,
          t("contractSummary.dateFormat")
        ),
        formatLocalDate(
          contractDetailInfo.contractEndDate,
          t("contractSummary.dateFormat")
        ),
        contractDetailInfo.terminationNoticePeriod,
        contractDetailInfo.isAutomaticallyUpdated == "true"
          ? t("template.updateOption1")
          : t("template.updateOption2"),
        contractDetailInfo.controlNumber,
        contractDetailInfo.transactionAmount.toString(),
        contractDetailInfo.freeText,
        ...contractInformation.contractCustomFields.map((e: any) => e.value),
      ].filter((e) => e !== "")
    );

    const next = () => {
      router.push({
        name: "ContractSummary",
        params: route.params,
        query: route.query,
      });
    };

    const onPageScaled = (file: any, scale: number) => {
      file.scale = scale;
      file.inputs
        ?.filter((input: any) => input.contractFileId === file.id)
        .forEach((input: any) => {
          input.w = input.originW * scale;
          input.h = input.originH * scale;
          input.x = input.originX * scale;
          input.y = input.originY * scale;
          input.scale = scale;
        });
    };

    const onPageLoaded = (file: any, page: number) => {
      file.inputs?.forEach((input: any) => {
        const scale = !input.scale || input.scale == 0.0 ? 1.0 : input.scale;
        input.originW = input.w / scale;
        input.originH = input.h / scale;
        input.originX = input.x / scale;
        input.originY = input.y / scale;
      });
    };

    onMounted(async () => {
      state.isMounted = true;
      checkingAndNavigation(contractInformation);
      state.canDelete = route.params.canDelete === "false" ? false : true;

      state.companyInfo = await getCompany();

      state.isEdit = route.query.isEdit === "true";
      state.onlyInformation = route.query.onlyInformation === "true";
      const { folderId, folderName, title } = contractInformation;
      state.folderId = folderId;
      state.folderName = folderName;
      state.contractStatus = route.params.status as string;

      if (state.isEdit) {
        state.progressItems = editProgressBarState();
        const folderPath = buildContractEditBreadScrumPath(
          folderId,
          folderName
        );
        state.breadScrumPaths = [
          { text: folderName, to: folderPath },
          { text: title, to: "#" },
        ] as never;
      }
    });

    const handleDrag = (e: any) => {
      const fileId = e.dataTransfer.getData("fileId");
      const file = state.files.find((item: any) => item.id === fileId);
      inputVars.addVarFromDrag(
        e,
        file,
        state.companyInfo.imprintType === 1 ? "round" : "square",
        state.companyInfo.imprintSize || 21
      );
    };

    return {
      ...toRefs(state),
      isMobile,
      ...inputVars,
      onPageScaled,
      onPageLoaded,
      onPageSized,
      onSave,
      deleteDraftContract,
      back,
      next,
      onEdit,
      resetState,
      contractInformation,
      handleDrag,
      backToFolder,
      enableNextButton,
      suggestTexts,
    };
  },
  beforeRouteLeave(to, from, next) {
    if (!to.path.includes("/contract")) {
      this.resetState();
    }
    next();
  },
  methods: {
    ...mapMutations("popup", ["setPopup", "unsetPopup"]),

    onSelectedFile(fileId: any) {
      const file = this.files.find((item: any) => item.id === fileId);
      this.isLoadingPdfPage = true;
      if (file) this.selectedFile = file;
    },
    onReady(file: any, pageNums: number) {
      file.pageNums = pageNums;
    },
    nextPage(file: any) {
      this.isLoadingPdfPage = true;
      if (file.currentPage < file.pageNums) {
        file.currentPage += 1;
      }
    },
    prevPage(file: any) {
      this.isLoadingPdfPage = true;
      if (file.currentPage > 1) {
        file.currentPage -= 1;
      }
    },
    setPage(file: FileInfo, e: any) {
      this.isLoadingPdfPage = true;
      if (!file.currentPage && !file.pageNums) {
        file.currentPage = 1;
        return;
      }
      const page = parseInt(e.target.value, 10);
      if (page <= 0) {
        file.currentPage = 1;
        e.target.value = 1;
        return;
      } else if (page >= (file.pageNums || 0)) {
        file.currentPage = file.pageNums;
        e.target.value = file.pageNums;
        this.isLoadingPdfPage = false;
        return;
      } else if (page) file.currentPage = page;
    },
  },
});
